import React from 'react';
import Img from 'gatsby-image';

import Button from 'components/common/button/Button';
import Carousel from 'components/directus/carousel/Carousel';
import SectionTitle from 'components/directus/section-title/SectionTitle';
import companyStructureHook from 'hooks/company-structure/company-structure.hook';
import sectionTitleHook from 'hooks/section-title/section-title.hook';
import { getFieldBySlug } from 'utils/directus';

import s from './CompanyStructure.scss';

export default (): JSX.Element => {
    const structures = companyStructureHook();
    const { node } = getFieldBySlug('company-structure', sectionTitleHook());

    return (
        <>
            <SectionTitle title={node.heading} align="center" />

            <div className={s.company}>
                <Carousel
                    width={377}
                    height={558}
                    maxVisibleSlides={3}
                    mdSlidesToShow={1}
                    changeOnResize
                >
                    {structures.map(({ node }): JSX.Element => (
                        <div key={node.directusId} className={s.company__block}>
                            <Img
                                className={s.company__image}
                                fluid={node.image.localFile.childImageSharp.fluid}
                                fadeIn
                            />
                            <div className={s.company__content}>
                                <h3 className={s.company__title}>{node.title}</h3>
                                <p className={s.company__body}>{node.body}</p>
                            </div>

                            <div className={s.company__content}>
                                <Button
                                    role="secondary"
                                    to={node.link}
                                    className={s.company__link}
                                    block
                                >
                                    {node.button_text}
                                </Button>
                            </div>
                        </div>
                    ))}
                </Carousel>
            </div>
        </>
    );
};
