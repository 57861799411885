import { graphql, useStaticQuery } from 'gatsby';
import { WordPressPostsEdges, AllWordPressPosts } from './wordpress-posts.interface';

export default (): WordPressPostsEdges[] => {
    const { allWordPressPosts } = useStaticQuery<AllWordPressPosts>(graphql`
        query {
            allWordPressPosts(limit: 3){
                edges{
                    node{
                        slug
                        title
                        content
                        link
                        excerpt
                        featured_media {
                            medium
                        }
                    }
                }
            }

        }
    `);

    return allWordPressPosts.edges;
};
