import React from 'react';
import LazyLoad from 'react-lazy-load';

import Button from 'components/common/button/Button';
import SectionTitle from 'components/directus/section-title/SectionTitle';
import sectionTitleHook from 'hooks/section-title/section-title.hook';
import wordpressPostsHook from 'hooks/wordpress/wordpress-posts.hook';
import { getFieldBySlug } from 'utils/directus';

import s from './BlogPost.scss';

export default (): JSX.Element => {
    const blogPosts = wordpressPostsHook();
    const { node } = getFieldBySlug('homepage-blog-posts', sectionTitleHook());

    return (
        <>
            <SectionTitle title={node.heading} align="center" />
            <div className={s.wpPosts}>
                {blogPosts.map(({ node }) => (
                    <div key={node.slug} className={s.wpPosts__post}>
                        <LazyLoad>
                            <img src={node.featured_media.medium} alt={node.title} />
                        </LazyLoad>
                        <div className={s.wpPosts__post__content}>
                            <h3 key={node.slug}>{node.title}</h3>
                            <Button role="secondary" to={node.link} className={s.wpPosts__post__content_button}>
                                Read More
                            </Button>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};
