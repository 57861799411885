import React from 'react';
import slugify from 'react-slugify';
import Img from 'gatsby-image';

import Button from 'components/common/button/Button';
import ButtonGroup from 'components/common/button-group/ButtonGroup';
import Markdown from 'components/common/markdown/Markdown';
import PriceWithoutVat from 'components/common/price-without-vat/PriceWithoutVat';
import SectionTitle from 'components/directus/section-title/SectionTitle';
import registeredOfficesHook from 'hooks/our-registered-offices/our-registered-offices.hook';
import sectionTitleHook from 'hooks/section-title/section-title.hook';
import { getFieldBySlug } from 'utils/directus';

import s from './RegisteredOffices.scss';

export default (): JSX.Element => {
    const offices = registeredOfficesHook();
    const { node: section } = getFieldBySlug('registered-offices', sectionTitleHook());

    return (
        <div className={s.ourRegisteredOffices}>
            <SectionTitle title={section.heading} align="center" />
            <div className={s.ourRegisteredOffices__offices}>
                {offices.map(({ node }) => (
                    <div key={`office-${slugify(node.heading)}`} className={s.ourRegisteredOffices__office}>
                        <Img
                            className={s.ourRegisteredOffices__image}
                            fluid={node.image.localFile.childImageSharp.fluid}
                            fadeIn
                        />
                        <h3 className={s.ourRegisteredOffices__heading}>
                            <Markdown source={node.heading} />
                        </h3>
                        <p className={s.ourRegisteredOffices__price}>
                            <PriceWithoutVat price={node.price.value} /> { node.term }
                        </p>
                        <Markdown className={s.ourRegisteredOffices__content} source={node.content} container />
                        <ButtonGroup block className={s.ourRegisteredOffices__cta}>
                            <Button
                                role="secondary"
                                size="large"
                                to={node.button_link}
                            >
                                {node.button_text}
                            </Button>
                        </ButtonGroup>
                    </div>
                ))}
            </div>
        </div>
    );
};
