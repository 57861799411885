import React from 'react';

import Button from 'components/common/button/Button';
import ButtonGroup from 'components/common/button-group/ButtonGroup';
import Section from 'components/common/section/Section';
import { Wrapper } from 'components/common/wrapper/Wrapper';
import SectionTitle from 'components/directus/section-title/SectionTitle';
import sectionTitleHook from 'hooks/section-title/section-title.hook';
import { getFieldBySlug } from 'utils/directus';
import PackageGrid from '../PackageGrid';

import s from './index.scss';

export default (): JSX.Element => {
    const { node } = getFieldBySlug('package-grid', sectionTitleHook());

    return (
        <Section colour="grey" isHome>
            <Wrapper>
                <div className={s.homepagePackage}>
                    <SectionTitle
                        title={node.heading}
                        subtitle={node.subheading}
                        align="center"
                        className={s.sectionSubtitle}
                    />

                    <PackageGrid />

                    <ButtonGroup block className={s.buttonContainer}>
                        <Button
                            size="large"
                            role="secondary"
                            to="/compare-packages/">
                                Compare Packages
                        </Button>
                    </ButtonGroup>
                    <h4 className={s.homepagePackage__phoneorder}>
                        Order by telephone - call &nbsp;
                        <a href="tel:020 7871 9990">
                            020 7871 9990
                        </a>
                    </h4>
                </div>
            </Wrapper>
        </Section>
    );
};
