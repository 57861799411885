import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusCompanyStructure, CompanyStructureEdge } from './company-structure.interface';

export default (): CompanyStructureEdge[] => {
    const { allDirectusCompanyStructure } = useStaticQuery<AllDirectusCompanyStructure>(graphql`
    query {
      allDirectusCompanyStructure(sort: { fields: sort, order: ASC }) {
        edges {
          node {
            directusId
            image {
              data {
                full_url
              }
              width
              height
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1920, maxHeight: 1080) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            image_alt
            title
            body
            button_text
            link
          }
        }
      }
    }
  `);

    return allDirectusCompanyStructure.edges;
};
