import React from 'react';
import Img from 'gatsby-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import scrollTo from 'gatsby-plugin-smoothscroll';

import Button from 'components/common/button/Button';
import ButtonGroup from 'components/common/button-group/ButtonGroup';
import Markdown from 'components/common/markdown/Markdown';
import Carousel from 'components/directus/carousel/Carousel';
import SectionTitle from 'components/directus/section-title/SectionTitle';
import fourStepHook from 'hooks/four-steps/four-steps.hook';
import sectionTitleHook from 'hooks/section-title/section-title.hook';
import { getFieldBySlug } from 'utils/directus';
import { StepBlock } from './FourSteps.interface';

import s from './FourSteps.scss';

const StepContainer = ({ image, heading, step }: StepBlock) => (
    <div className={s.fourSteps__step}>
        <div className={s.fourSteps__image}>
            <Img
                fluid={image.localFile.childImageSharp.fluid}
                alt={`${heading} four step image.`}
                style={{ width: `${image.width}px`, height: '181px', margin: '0 auto' }}
                fadeIn
            />
        </div>

        <div className={s.fourSteps__body}>
            <div className={s.fourSteps__number}>{step}</div>
            <h4 className={s.fourSteps__heading}>
                <Markdown source={heading} />
            </h4>
        </div>

        {step < 4 && (
            <div className={s.fourSteps__icon}>
                <FontAwesomeIcon icon={faChevronRight} />
            </div>
        )}
    </div>
);

export default (): JSX.Element => {
    const steps = fourStepHook();
    const { node } = getFieldBySlug('four-step', sectionTitleHook());

    return (
        <>
            <SectionTitle
                title={node.heading}
                subtitle={node.subheading}
                align="center"
            />
            <div className={s.fourSteps}>
                {steps.map(({ node }): JSX.Element => (
                    <StepContainer
                        key={`four-steps-${node.directusId}`}
                        image={node.image}
                        heading={node.heading}
                        step={node.number}
                    />
                ))}
            </div>
            <div className={s.fourStepsMobile}>
                <Carousel width={300} height={225} maxVisibleSlides={1} changeOnResize>
                    {steps.map(({ node }): JSX.Element => (
                        <StepContainer
                            key={`four-steps-mobile-${node.directusId}`}
                            image={node.image}
                            heading={node.heading}
                            step={node.number}
                        />
                    ))}
                </Carousel>
            </div>
            <ButtonGroup block className={s.fourSteps__cta}>
                <Button
                    role="primary"
                    size="large"
                    onClick={() => scrollTo('#home-top')}
                >
                    Get Started
                </Button>
            </ButtonGroup>
        </>
    );
};
