import React from 'react';
import BackgroundImage from 'gatsby-background-image';

import Markdown from 'components/common/markdown/Markdown';
import Carousel from 'components/directus/carousel/Carousel';
import SectionTitle from 'components/directus/section-title/SectionTitle';
import sectionTitleHook from 'hooks/section-title/section-title.hook';
import WhyChooseUsHook from 'hooks/why-choose-us/why-choose-us.hook';
import { getFieldBySlug } from 'utils/directus';
import { CarouselProps } from './WhyChooseUs.interface';

import s from './WhyChooseUs.scss';

export default (): JSX.Element => {
    const whyChooseUs = WhyChooseUsHook();
    const { node: section } = getFieldBySlug('why-choose-us', sectionTitleHook());

    const CarouselPane = ({ title, body, image }: CarouselProps) => (
        <div className={s.carouselPane}>
            <div className={s.carouselPane__left}>
                <h3 className={s.carouselPane__left__title}>{title}</h3>
                <Markdown className={s.carouselPane__left__body} source={body} container />
            </div>
            <BackgroundImage className={s.carouselPane__right} fluid={image.localFile.childImageSharp.fluid} fadeIn />
        </div>
    );

    return (
        <>
            <SectionTitle
                title={section.heading}
                align="center"
            />
            <Carousel
                height={420}
                width={730}
                maxVisibleSlides={1}
                hasArrows={false}
                dots>

                {whyChooseUs.map(({ node }) => (
                    <div className={s.whyChooseUs} key={node.title}>
                        <CarouselPane
                            title={node.title}
                            body={node.body}
                            image={node.image}
                        />
                    </div>
                ))}
            </Carousel>
        </>
    );
};
